<!-- <div [ngStyle]="{'background-color':globals['bgColor'],'color':globals['color']}"> -->
<div [ngStyle]="{'background-color':'black','color':'white'}">
  <router-outlet (activate)="scrollToTop()"></router-outlet>
</div>
<ngx-ui-loader fgsColor="#ffffff" pbColor="#287bc0" overlayColor="rgb(0 0 0 / 60%)" hasProgressBar="true"
  pbThickness="10" textPosition="center-center" gap="0" logoPosition="center-center" logoSize="120"
  logoUrl="assets/images/logo/favicon.ico" [fgsTemplate]="foregroundSpinner">
</ngx-ui-loader>
<ng-template #foregroundSpinner></ng-template>
<!-----------------------footer & header contact pop up--------------------------------------->
<div class="etheraContactPopup animate__animated animate__fadeInDown" *ngIf="globals.openAllContactPopup">
  <div class="contactPopupCrossIcon">
    <img alt="popupCrossIcon" src="assets/images/icons/popupCrossIcon.png" width="100%"
      (click)="globals.openAllContactPopup = false;openFormBox = true;hideScroll();">
  </div>
  <div class="etheraContactPopupInner col-12 col-lg-9 flex-wrap d-flex flex-column-reverse flex-lg-row">
    <div class="col-12 col-lg-6 align-self-center d-flex flex-column etheraContactRefrence">
      <div class="d-none d-lg-flex align-items-start"> <img alt="creditasSolutionsLogo" src="assets/images/logo/creditasSolutions.png"
          width="120px"></div>
      <div class="etheraContactRefrenceHeading d-none d-lg-flex">
        <h1>Redefining Customer Engagement <br>for the Digital Era</h1>
      </div>
      <div class="mt-3">
        <div class="etheraContactRefrenceNumber d-flex d-lg-none">General Queries - <a href="callto:+91-9818503541">+91-9818503541</a></div>
        <div class="etheraContactRefrenceNumber d-none d-lg-flex">General Queries - +91-9818503541</div>
        <div class="etheraContactRefrenceNumber d-flex d-lg-none">UK Queries - <a href="callto:+44 7956 551590">+44 7956 551590</a></div>
        <div class="etheraContactRefrenceNumber d-none d-lg-flex">UK Queries - +44 7956 551590</div>
      </div>

      
      
      <div class="etheraContactRefrenceEmail d-flex"><a
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@creditas.in"
          target="_blank">info&#64;creditas.in</a></div>
      <div class="etheraContactRefrenceAddress">
        <p>Plot No 63, Huda Colony, Sector 44, Gurugram,<br>Haryana 122003</p>
      </div>
      <div class="etheraContactRefrenceMap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.4172923593796!2d77.04650328714747!3d28.4368346133025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19a9c78b06df%3A0xff60a7fb20416cb5!2sCreditas%20Solutions!5e0!3m2!1sen!2sin!4v1718272571677!5m2!1sen!2sin"
          allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div>
    <div class="col-12 col-lg-6 align-self-center d-flex flex-column">
      <div class="d-flex d-lg-none align-items-start mt-5"> <img alt="creditasSolutionsLogo" src="assets/images/logo/creditasSolutions.png"
          width="120px"></div>
      <div class="d-flex d-lg-none etheraContactPopupHaeding">Redefining Customer Engagement for the Digital Era</div>
      <div class="etheraContactFormBox w-100 formBlock"  *ngIf="openFormBox">
        <form #allContactForm="ngForm" (ngSubmit)="allContactForm.valid && globals.checkTnC  && onSubmitContactUs(allContactForm)">
          <div class="formLabel" required>Your Name</div>
          <input class="nameInput " type="text" name="username" ngModel #username="ngModel" required />
          <div class="validationBox">
            <div *ngIf="allContactForm.submitted && username.invalid">
              <span *ngIf="username.errors.required">Name is required*</span>
            </div>
          </div>
          <div class="formLabel" required>Phone Number</div>
          <input class="nameInput " type="number" name="phoneNumber" ngModel #phoneNumber="ngModel"
            pattern="^([6-9]){1}([0-9]){9}?$" required />
          <div class="validationBox">
            <div *ngIf="allContactForm.submitted && phoneNumber.invalid">
              <span *ngIf="phoneNumber.errors.required">Phone number is required*</span>
              <span *ngIf="phoneNumber.errors.pattern">Phone number is invalid !!</span>
            </div>
          </div>
          <div class="formLabel" required>Your Email</div>
          <input class="nameInput " type="email" name="email" ngModel #email="ngModel"
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required />
          <div class="validationBox">
            <div *ngIf="allContactForm.submitted && email.invalid">
              <span *ngIf="email.errors.required">Email is required*</span>
              <span *ngIf="email.errors.pattern">Email is invalid !!</span>
            </div>
          </div>
          <div class="formLabel">Message</div>
          <textarea class="textareaInput" name="message" ngModel #message="ngModel" required></textarea>
          <div class="validationBox">
            <div *ngIf="allContactForm.submitted && message.invalid">
              <span *ngIf="message.errors.required">Message is required*</span>
              <span *ngIf="message.errors.pattern">Minimum two Character is required*</span>
            </div>
          </div>
          <div>
            <app-termcondition [formName]="allContactForm" ></app-termcondition>
          </div>
          <button class="etheraContactRefrenceBtn" type="submit">Submit<img alt="triangleArrow" class="ml-2"
              src="assets/images/icons/play.png"></button>
        </form>
      </div>
      <div class="etheraContactFormBox w-100 py-lg-5" *ngIf="!openFormBox">
        <div class="otpSubmitHeading">Please enter the OTP sent to your Phone and Email address</div>
        <form #allContactOtpSubmit="ngForm"
          (ngSubmit)="allContactOtpSubmit.valid && allContactSubmitOtps(allContactOtpSubmit)">
          <div class="formLabel" required>Enter Mobile OTP</div>
          <input class="nameInput  " type="number" name="mobileOtp" ngModel #mobileOtp="ngModel" required />
          <div class="validationBox">
            <div *ngIf="allContactOtpSubmit.submitted && mobileOtp.invalid">
              <span *ngIf="mobileOtp.errors.required">Mobile OTP is required*</span>
            </div>
          </div>
          <div class="formLabel" required>Enter Email OTP</div>
          <input class="nameInput" type="number" name="emailOtp" ngModel #emailOtp="ngModel" required />
          <div class="validationBox">
            <div *ngIf="allContactOtpSubmit.submitted && emailOtp.invalid">
              <span *ngIf="emailOtp.errors.required">Email OTP is required*</span>
            </div>
          </div>
          <button class="etheraContactRefrenceBtn mt-3" type="submit">OTP Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>

<!------------------career modal popup----------------------------------------------->
<ng-template #careerModal let-modal>
  <div class="modal-body p-0">
    <div class="px-3 pt-3 text-right justify-content-between d-flex">
      <div></div>
      <div class="otpSubmitHeading" *ngIf="openFormBox">{{globals['careerPost']}}</div>
      <div class="otpSubmitHeading" *ngIf="!openFormBox">Please enter the OTP sent to <br>your Phone and Email address
      </div>
      <img alt="popupCrossIcon" class="cursorPointer modalPopupCrosIcon align-self-center"
        src="assets/images/icons/popupCrossIcon.png" data-dismiss="careerModal"
        (click)="openFormBox= true;closeModal()">
    </div>
    <div class="col-12 px-0 align-self-center d-flex flex-column">
      <div class="etheraContactFormBox border-0 w-100 pt-0" *ngIf="openFormBox">
        <form #careerContactForm = "ngForm"
          (ngSubmit)="careerContactForm.valid && globals.checkTnC && onSubmitCareerRequest(careerContactForm)">
          <div class="formLabel mt-0" required>Your Name</div>
          <input class="nameInput " type="text" name="username" ngModel #username="ngModel" required />
          <div class="validationBox">
            <div *ngIf="careerContactForm.submitted && username.invalid">
              <span *ngIf="username.errors.required">Name is required*</span>
            </div>
          </div>
          <div class="formLabel" required>Phone Number</div>
          <input class="nameInput " type="number" name="phoneNumber" ngModel #phoneNumber="ngModel"
            pattern="^([6-9]){1}([0-9]){9}?$" required />
          <div class="validationBox">
            <div *ngIf="careerContactForm.submitted && phoneNumber.invalid">
              <span *ngIf="phoneNumber.errors.required">Phone number is required*</span>
              <span *ngIf="phoneNumber.errors.pattern">Phone number is invalid !!</span>
            </div>
          </div>
         <div class="formLabel" required>Your Email</div>
          <input class="nameInput " type="email" name="email" ngModel #email="ngModel"
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required />
          <div class="validationBox">
            <div *ngIf="careerContactForm.submitted && email.invalid">
              <span *ngIf="email.errors.required">Email is required*</span>
              <span *ngIf="email.errors.pattern">Email is invalid !!</span>
            </div>
          </div> 
          <div class="formLabel" required>Upload Resume</div>
          <div class="col-12 px-0 d-flex">
            <label class="nameInput cursorPointer" for="uploadResume">{{ fileNameResume ? fileNameResume :'Choose
              File'}}</label>
            <input class="resumeInput d-none" type="file" id="uploadResume" name="resume" ngModel #resume="ngModel"
              (change)="uploadFileResume($event)" required />
          </div>
          <div class="validationBox">
            <span *ngIf="careerContactForm.submitted && resume.invalid">
              <span *ngIf="resume.errors.required" class="mr-2">Resume is required*</span>
            </span>
            <span *ngIf ="fileSizeIsBigger" class="text-secondary">File size must be less than 1MB</span>
          </div>

          <div class="formLabel">Message</div>
          <textarea class="textareaInput" name="message" ngModel #message="ngModel" required></textarea>
          <div class="validationBox">
            <div *ngIf="careerContactForm.submitted && message.invalid">
              <span *ngIf="message.errors.required">Message is required*</span>
              <span *ngIf="message.errors.pattern">Minimum two Character is required*</span>
            </div>
          </div>
          <div>
            <app-termcondition [formName]="careerContactForm" ></app-termcondition>
          </div>
          <button class="etheraContactRefrenceBtn" type="submit">Apply Now<img alt="triangleArrow" class="ml-2"
              src="assets/images/icons/play.png"></button>
        </form>
        <!-- <div class="careerPopupFooterContent pt-3 text-center">Please share resume on <u>careers@creditas.in</u></div> -->
      </div>
      <div class="etheraContactFormBox border-0 w-100 py-lg-4" *ngIf="!openFormBox">
        <form #careerOtpSubmitForm="ngForm"
          (ngSubmit)="careerOtpSubmitForm.valid && careerOtpSubmit(careerOtpSubmitForm)">
          <div class="formLabel" required>Enter Mobile OTP</div>
          <input class="nameInput  " type="number" name="mobileOtp" ngModel #mobileOtp="ngModel" required />
          <div class="validationBox">
            <div *ngIf="careerOtpSubmitForm.submitted && mobileOtp.invalid">
              <span *ngIf="mobileOtp.errors.required">Mobile OTP is required*</span>
            </div>
          </div>
          <div class="formLabel" required>Enter Email OTP</div>
          <input class="nameInput" type="number" name="emailOtp" ngModel #emailOtp="ngModel" required />
          <div class="validationBox">
            <div *ngIf="careerOtpSubmitForm.submitted && emailOtp.invalid">
              <span *ngIf="emailOtp.errors.required">Email OTP is required*</span>
            </div>
          </div>
          <button class="etheraContactRefrenceBtn mt-3" type="submit">OTP Submit</button>
        </form>
        <!-- <div class="text-white shareResume">Please share resume on <u>careers@creditas.in</u></div> -->
      </div>
    </div>
  </div>
</ng-template>
<!----------------demo modal popup------------------------------------------>
<ng-template #demoModal let-modal>
  <div class="modal-body p-0">
    <div class="p-3 text-right justify-content-between d-flex">
      <div></div>
      <div class="otpSubmitHeading" *ngIf="!openFormBox">Please enter the OTP sent to <br>your Phone and Email address
      </div>
      <img alt="popupCrossIcon" class="cursorPointer modalPopupCrosIcon align-self-center"
        src="assets/images/icons/popupCrossIcon.png" data-dismiss="careerModal"
        (click)="openFormBox= true;closeModal()">
    </div>
    <div class="col-12 px-0 align-self-center d-flex flex-column">
      <div class="etheraContactFormBox border-0 w-100 pt-0" *ngIf="openFormBox">
        <form #contactForm="ngForm" (ngSubmit)="contactForm.valid && globals.checkTnC && onSubmitDemoRequest(contactForm)">
          <div class="formLabel" required>Your Name</div>
          <input class="nameInput " type="text" name="username" ngModel #username="ngModel" required />
          <div class="validationBox">
            <div *ngIf="contactForm.submitted && username.invalid">
              <span *ngIf="username.errors.required">Name is required*</span>
            </div>
          </div>
          <div class="formLabel" required>Phone Number</div>
          <input class="nameInput " type="number" name="phoneNumber" ngModel #phoneNumber="ngModel"
            pattern="^([6-9]){1}([0-9]){9}?$" required />
          <div class="validationBox">
            <div *ngIf="contactForm.submitted && phoneNumber.invalid">
              <span *ngIf="phoneNumber.errors.required">Phone number is required*</span>
              <span *ngIf="phoneNumber.errors.pattern">Phone number is invalid !!</span>
            </div>
          </div>
          <div class="formLabel" required>Your Email</div>
          <input class="nameInput " type="email" name="email" ngModel #email="ngModel"
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required />
          <div class="validationBox">
            <div *ngIf="contactForm.submitted && email.invalid">
              <span *ngIf="email.errors.required">Email is required*</span>
              <span *ngIf="email.errors.pattern">Email is invalid !!</span>
            </div>
          </div>
          <div class="formLabel" >Company Name</div>
          <input class="nameInput " type="text" name="company" ngModel #company="ngModel" />
          <div class="formLabel  selectLabel">Choose an Option</div>
          <div class="enquiryOption">
            <mat-form-field>
              <mat-select name="message" ngModel #message="ngModel" required>
                <mat-option *ngFor="let enquiry of enquiryList" [value]="enquiry">
                  {{enquiry}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="validationBox">
            <div *ngIf="contactForm.submitted && message.invalid">
              <span *ngIf="message.errors.required">Option is required*</span>
            </div>
          </div>
          <!-- <div class="formLabel">Message</div>
          <textarea class="textareaInput" name="message" ngModel #message="ngModel" required></textarea>
          <div class="validationBox">
            <div *ngIf="contactForm.submitted && message.invalid">
              <span *ngIf="message.errors.required">Message is required*</span>
              <span *ngIf="message.errors.pattern">Minimum two Character is required*</span>
            </div>
          </div> -->
          <div>
              <app-termcondition [formName] = "contactForm" ></app-termcondition>
          </div>
          <button class="etheraContactRefrenceBtn" type="submit">Request for a Demo<img alt="triangleArrow" class="ml-2"
              src="assets/images/icons/play.png"></button>
        </form>
      </div>
      <div class="etheraContactFormBox border-0 w-100 py-lg-4" *ngIf="!openFormBox">
        <form #otpSubmitForm="ngForm" (ngSubmit)="otpSubmitForm.valid && submitOtps(otpSubmitForm)">
          <div class="formLabel" required>Enter Mobile OTP</div>
          <input class="nameInput  " type="number" name="mobileOtp" ngModel #mobileOtp="ngModel" required />
          <div class="validationBox">
            <div *ngIf="otpSubmitForm.submitted && mobileOtp.invalid">
              <span *ngIf="mobileOtp.errors.required">Mobile OTP is required*</span>
            </div>
          </div>
          <div class="formLabel" required>Enter Email OTP</div>
          <input class="nameInput" type="number" name="emailOtp" ngModel #emailOtp="ngModel" required />
          <div class="validationBox">
            <div *ngIf="otpSubmitForm.submitted && emailOtp.invalid">
              <span *ngIf="emailOtp.errors.required">Email OTP is required*</span>
            </div>
          </div>
          <button class="etheraContactRefrenceBtn mt-3" type="submit">OTP Submit</button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-------------Ethera Case study----------------------->
<ng-template #brochureModal let-modal>
  <div class="modal-body p-0">
    <div class="p-3 text-right justify-content-between d-flex">
      <div></div>
      <!-- <div class="otpSubmitHeading" *ngIf="!openFormBox">Please enter the OTP sent to <br>your Phone and Email address
      </div> -->
      <img alt="popupCrossIcon" class="cursorPointer modalPopupCrosIcon align-self-center"
        src="assets/images/icons/popupCrossIcon.png" data-dismiss="brochureModal"
        (click)="closeModal()">
    </div>
    <div class="col-12 px-0 align-self-center d-flex flex-column">
      <div class="etheraContactFormBox border-0 w-100 pt-0">
        <form #caseStudyForm="ngForm" (ngSubmit)="caseStudyForm.valid && globals.checkTnC  && caseStudyReq(caseStudyForm)">
          <div class="formLabel" required>Phone Number</div>
          <input class="nameInput " type="number" name="phoneNumber" ngModel #phoneNumber="ngModel"
            pattern="^([6-9]){1}([0-9]){9}?$" required />
          <div class="validationBox">
            <div *ngIf="caseStudyForm.submitted && phoneNumber.invalid">
              <span *ngIf="phoneNumber.errors.required">Phone number is required*</span>
              <span *ngIf="phoneNumber.errors.pattern">Phone number is invalid !!</span>
            </div>
          </div>
          <div class="formLabel" required>Name</div>
          <input class="nameInput " type="text" name="userName" ngModel #userName="ngModel" required />
          <!-- <input class="nameInput " type="email" name="email" ngModel #email="ngModel"
            pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" required /> -->
          <div class="validationBox">
            <div *ngIf="caseStudyForm.submitted && userName.invalid">
              <span *ngIf="userName.errors.required">Name is required*</span>
              <!-- <span *ngIf="email.errors.pattern">Email is invalid !!</span> -->
            </div>
          </div>
          <div class="formLabel" >Company Name</div>
          <input class="nameInput" type="text" name="company" ngModel #company="ngModel" required/>
          <div class="validationBox">
            <div *ngIf="caseStudyForm.submitted && company.invalid">
              <span *ngIf="company.errors.required">Company name is required*</span>
            </div>
          </div>
          <div>
            <app-termcondition [formName]="caseStudyForm"></app-termcondition>
          </div>
          <button class="etheraContactRefrenceBtn" type="submit">Submit<img alt="triangleArrow" class="ml-2"
              src="assets/images/icons/play.png"></button>
        </form>
      </div>
  </div>
</div>
</ng-template>
<!----------------------Hyperion Case study----------------------------------------------------->
<ng-template #hyperionCaseStudy let-modal>
  <div class="modal-body p-0">
    <div class="p-3 text-right justify-content-between d-flex">
      <div></div>
      <img alt="popupCrossIcon" class="cursorPointer modalPopupCrosIcon align-self-center"
        src="assets/images/icons/popupCrossIcon.png" data-dismiss="hyperionCaseStudy"
        (click)="closeModal()">
    </div>
    <div class="col-12 px-0 align-self-center d-flex flex-column">
      <div class="etheraContactFormBox border-0 w-100 pt-0">
        <form #hyperionCaseStudyForm="ngForm" (ngSubmit)="hyperionCaseStudyForm.valid && hyperionCaseStudyReq(hyperionCaseStudyForm)">
          <div class="formLabel" required>Phone Number</div>
          <input class="nameInput " type="number" name="phoneNumber" ngModel #phoneNumber="ngModel"
            pattern="^([6-9]){1}([0-9]){9}?$" required />
          <div class="validationBox">
            <div *ngIf="hyperionCaseStudyForm.submitted && phoneNumber.invalid">
              <span *ngIf="phoneNumber.errors.required">Phone number is required*</span>
              <span *ngIf="phoneNumber.errors.pattern">Phone number is invalid !!</span>
            </div>
          </div>
          <div class="formLabel" required>Name</div>
          <input class="nameInput " type="text" name="userName" ngModel #userName="ngModel" required />
          <div class="validationBox">
            <div *ngIf="hyperionCaseStudyForm.submitted && userName.invalid">
              <span *ngIf="userName.errors.required">Name is required*</span>
            </div>
          </div>
          <div class="formLabel" >Company Name</div>
          <input class="nameInput" type="text" name="company" ngModel #company="ngModel" required/>
          <div class="validationBox">
            <div *ngIf="hyperionCaseStudyForm.submitted && company.invalid">
              <span *ngIf="company.errors.required">Company name is required*</span>
            </div>
          </div>
          <button class="etheraContactRefrenceBtn" type="submit">Submit1<img alt="triangleArrow" class="ml-2"
              src="assets/images/icons/play.png"></button>
        </form>
      </div>
  </div>
</div>
</ng-template>


<ng-template #successModal let-modal>
  <div class="modal-body px-0">
    <div class="col-12 px-0 d-flex flex-wrap">
      <div class="col-12 text-center"><img alt="successImg" src="assets/images/icons/successImg.png" width="120px">
      </div>
      <div class="col-12 mt-3 px-0 d-flex justify-content-center">
        <div class="sucessContent text-center">
          <b>Thank You</b>
          <br><span *ngIf="!caseStudy">We will contact you shortly</span><span *ngIf="caseStudy">for your response</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>



<a class="d-none" href="assets/pdfFiles/etheraCaseStudy.pdf" target="_blank" id="etheraCaseStudyDownload"></a>
<a class="d-none" href="assets/pdfFiles/etheraCaseStudy.pdf" target="_blank" id="hyperionCaseStudyDownload"></a>
