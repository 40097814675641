import { Component, OnInit, HostListener, ViewChild, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import AOS from 'aos';
import { SPINNER } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Globals } from './app.globals';
import { NgForm } from '@angular/forms';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { Angulartics2, Angulartics2GoogleAnalytics } from 'angulartics2';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'creditas';
  SPINNER = SPINNER;
  openFormBox = true;
  userContactUsData: any;
  public modalReference: any;
  public file = false;
  public fileNameResume: any;
  fileData: any = {};
  public caseStudy = false;
  fileSizeIsBigger: boolean = true;
  enquiryList = [' Product enquiry & understanding','Request a demo','Collaborations / partnerships'];
  constructor(
    private router: Router,
    public globals: Globals,
    public authService: AuthService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private ngxService: NgxUiLoaderService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private angulartics2: Angulartics2,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,

  ) {
    angulartics2GoogleAnalytics.startTracking();
  }
  @ViewChild('successModal') successModal;
  @ViewChild('careerModal') careerModal;
  @ViewChild('demoModal') demoModal;
  @ViewChild('brochureModal') brochureModal;
  @ViewChild('hyperionCaseStudy') hyperionCaseStudy;

  @HostListener('window:beforeunload')
  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.closeModal();
    }
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      let unsafe =  window.location.href.includes("http://www")
      if(unsafe){
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
      }
      var x = window.matchMedia("(max-width: 992px)")
      if (x.matches) {
        this.globals['mobile'] = true;
      } else {
        this.globals['mobile'] = false;
        this.globals['desktop'] = true;
      }

      // localStorage.setItem('pageUrl', 'ethera');
      // this.router.navigate(['loading']);
      this.globals['bgColor'] = '#000000';
      this.globals['color'] = '#ffffff';
      AOS.init({
        duration: 2000
      });
    }
  }
  ngOnDestroy() {
    this.closeModal();
  }

  onSubmitDemoRequest(form: any) {
    this.ngxService.start();
    this.userContactUsData = null;
    this.authService.postDemoRequestFormData(form.form.value).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.openSuccessPopUp();
        this.userContactUsData = res;
        // this.openFormBox = false;
        this.trackClick('Submit', ' Request a Demo ');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
      }
    )
    this.globals.checkTnC = false;
  }
  submitOtps(form: any) {
    this.ngxService.start();
    this.authService.postDemoRequestOtp(form.form.value, this.userContactUsData).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.openSuccessPopUp();
        this.openFormBox = true;
        this.trackClick('Submit', ' Request a Demo OTP');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
        this.openFormBox = true;
      }
    );
  }

  onSubmitContactUs(form: any) {
    this.ngxService.start();
    this.userContactUsData = null;
    this.authService.postAllContactFormData(form.form.value).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.openSuccessPopUp();
        this.globals.openAllContactPopup = false;
        //  this.openFormBox = false;
        this.userContactUsData = res;
        this.hideScroll();
        this.trackClick('Submit', ' Contact Us');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
      }
    )
    this.globals.checkTnC = false;
  }
  allContactSubmitOtps(form: any) {
    this.ngxService.start();
    this.authService.postAllContactOtp(form.form.value, this.userContactUsData).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.openSuccessPopUp();
        this.globals.openAllContactPopup = false;
        this.openFormBox = true;
        this.hideScroll();
        this.trackClick('Submit', ' Contact Us OTP');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
        this.openFormBox = true;
      }
    );
  }

  onSubmitCareerRequest(form: any) {
    if (!this.fileSizeIsBigger) {
      this.ngxService.start();
      this.userContactUsData = null;
      this.uploadFile(this.fileData['file'], this.fileData['fileNameWithoutExtension'], this.fileData['fileExtension'], form.form.value,);
      this.trackClick('Submit', ' Job Request');
    }
  }
  careerOtpSubmit(form: any) {
    this.ngxService.start();
    this.authService.postCareerOtp(form.form.value, this.userContactUsData).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.openSuccessPopUp();
        this.openFormBox = true;
        this.trackClick('Submit', ' Career Form OTP');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
        this.openFormBox = true;
      }
    );
  }
  caseStudyReq(data: any) {
    this.ngxService.start();
    this.authService.postcaseStudyRequest(data.form.value).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.caseStudy = true;
        this.openSuccessPopUp();
        setTimeout(() => {
          // let newTab = window.open();
          // newTab.location.href = 'assets/pdfFiles/etheraCaseStudy.jpg';
          var activeClickBtn = document.getElementById("etheraCaseStudyDownload");
          activeClickBtn.click();
        }, 500);
        this.trackClick('Submit', ' Request for  Case Study ');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
      }
    )
    this.globals.checkTnC = false;
  }
  hyperionCaseStudyReq(data: any) {
    this.ngxService.start();
    this.authService.postcaseStudyRequest(data.form.value).subscribe(
      (res: any) => {
        this.ngxService.stop();
        this.caseStudy = true;
        this.openSuccessPopUp();
        setTimeout(() => {
          var activeClickBtn = document.getElementById("hyperionCaseStudyDownload");
          activeClickBtn.click();
        }, 500);
        this.trackClick('Submit', ' Request for Hyperion Case Study ');
      },
      (err: any) => {
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
      }
    )
  }
  openSuccessPopUp() {
    this.modalService.dismissAll();
    this.modalReference = this.modalService.open(this.successModal, { centered: true, windowClass: 'defaultModal successPopUp animate__animated animate__fadeIn' });
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.modalReference.close();
      }, 2500)
    }
  }

  public hideScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.globals.openAllContactPopup == true) {
        let body = document.getElementsByTagName('body');
        document.body.style.overflow = "hidden"
      } else {
        let body = document.getElementsByTagName('body');
        document.body.style.removeProperty('overflow');
      }
    }
  }

  openCareerModal() {
    this.openFormBox = true;
    this.fileNameResume = '';
    this.fileData = {};
    this.modalService.open(this.careerModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'defaultModal etheraContactModal animate__animated animate__fadeIn' });
  }
  openDemoModal() {
    this.openFormBox = true;
    this.modalService.open(this.demoModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'defaultModal requestDemoModal animate__animated animate__fadeIn' });

  }
  downloadCaseStudy() {
    this.modalService.open(this.brochureModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'defaultModal requestDemoModal' });
  }
  downloadHyperionCaseStudy() {
    this.modalService.open(this.hyperionCaseStudy, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'defaultModal requestDemoModal' });
  }
  closeModal() {
    if (isPlatformBrowser(this.platformId)) {
      this.modalService.dismissAll();
      this.globals.openAllContactPopup = false;
      let body = document.getElementsByTagName('body');
      document.body.style.removeProperty('overflow');
      this.globals.checkTnC = false;
    }
  }

  uploadFileResume(fileInput) {
    if (isPlatformBrowser(this.platformId)) {
      var file = fileInput.target.files[0];
      this.fileNameResume = file.name;
      var fileSize = file.size;
      fileSize = fileSize / 1024;
      if (1024 >= fileSize) {
        this.fileSizeIsBigger = false;
      } else {
        this.fileSizeIsBigger = true;
      }
      //Extracting filename without file extension
      let fileNameWithoutExtension = this.fileNameResume.substr(0, this.fileNameResume.lastIndexOf('.')).replace(/ /g, '');
      //Extracting file extension
      let fileExtension = this.fileNameResume.split('.').pop();
      //Showing loader
      //Calling service to upload file to s3
      this.fileData['file'] = file
      this.fileData['fileNameWithoutExtension'] = fileNameWithoutExtension
      this.fileData['fileExtension'] = fileExtension
    }
  }

  uploadFile(file, fileNameWithoutExtension, fileExtension, formValue) {
    this.authService.uploadFileToS3Service(file, fileNameWithoutExtension, fileExtension, this.generateRandomString()).subscribe(
      response => {
        // this.fileNameResumeAfter = response;
        this.authService.postCareerFormData(formValue, response).subscribe(
          (res: any) => {
            this.ngxService.stop();
            this.userContactUsData = res;
            // this.openFormBox = false;
            this.fileNameResume = undefined;
            this.fileData = {};
            this.globals.checkTnC = false;
            this.openSuccessPopUp();
            this.openFormBox = true;
          },
          (err: any) => {
            this.fileNameResume = undefined;
            this.fileData = {};
            this.ngxService.stop();
            this.toastr.error('Something went wrong please try again');
            this.globals.checkTnC = false;
          }
          
        )
      },
      error => {
        //Hiding loader
        this.fileNameResume = '';
        this.fileNameResume = undefined;
        this.fileData = {};
        this.ngxService.stop();
        this.toastr.error('Something went wrong please try again');
      }
    );
  }

  public generateRandomString() {
    if (isPlatformBrowser(this.platformId)) {
      var text: string = "";
      //All possible characters in random string
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 8; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    }
  }

  fadeSection(element, sectionBlock, opacity, percent, nextSectionBlock, nextElement) {
    if (isPlatformBrowser(this.platformId)) {
      var distanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
      var elementHeight = element.offsetHeight;
      var scrollTop = document.documentElement.scrollTop;
      opacity = 1;
      if (scrollTop > distanceToTop) {
        opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
      } else {
        if (!this.globals['mobile']) {
          opacity = 1 + (scrollTop - distanceToTop) / elementHeight;
        }
      }
      if (opacity >= 0) {
        this.renderer.setStyle(element, 'opacity', opacity);
      }
    }
  }

  trackClick(action: any, clickLabel: any) {
    this.angulartics2.eventTrack.next({
      action: action,
      properties: {
        label: clickLabel,
      },
    });
    this.authService.trackClickFun(action, clickLabel).subscribe(
      (res: any) => {
      },
      (err: any) => {
      }
    );
  }
}
