import { BrowserModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { Globals } from './app.globals';
import { FormsModule }   from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService} from './auth.service';
import { ToastrModule } from 'ngx-toastr';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import {SharedModule} from '../app/shared/shared.module'
import { Angulartics2Module } from 'angulartics2';



@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxUiLoaderModule,
        FormsModule,
        NgbModule,
        ToastrModule.forRoot(),
        Angulartics2Module.forRoot(),
        MatInputModule,
        MatSelectModule,
        SharedModule], providers: [Globals, AuthService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
